
import React from 'react'
import Layout from '../../components/Layout'
import { Link } from 'gatsby'
import * as styles from '../../assets/css/surveys.module.css'

const SurveyTeofilo = () => {

  return (
    <Layout>
      <section>
        <h1>Seaside Town Architect’s Survey</h1>
        <h2>Teófilo Victoria</h2>
        <section>
          <p className={styles.bold}>Can you give a brief summary of your history in architecture (i.e. education, early experience, etc.)?</p>
          <p>I was a student of Naval Architecture in Germany before studying Architecture. In 1978, I earned a Bachelor of Fine Arts from RISD and in 1979 a Bachelors of Architecture, also from RISD. In 1982, I obtained a Master’s Degree in Architecture and Urban Design from Columbia University. Professionally, I worked for Paul Rudolph in 1980 and in the same year in Arquitectonica where I worked on the initial drawings of Seaside. In 1983 with Maria de la Guardia, we founded the Office of Architecture and Monumental Art which would later evolved into delaGuardia Architecture and Urbanists.</p>

          <p className={styles.bold}>What initially drew you to Seaside?</p>
          <p>I had met Andrés Duany and his cousin, Ernesto Buch in Miami. In 1980 I worked with André on early versions of Seaside while André and Lizz were still with Arquitectonica. Robert Davis had commissioned the firm to propose a master plan for the development of a relatively large parcel of land in the Florida panhandle. After graduating from Columbia in 1982 Andrés asked Ernesto Buch, Victoria Casasco and me to join the Seaside charette. As far as I know it was the first charette since the Beaux Arts.</p>

          <p className={styles.bold}>When did you serve as Seaside Town Architect?</p>
          <p>I was the first Town Architect in Seaside in 1983. The idea of the charette, of the town architect and the viability of architectural zoning codes were being tested by André and Lizz as part of a comprehensive conception of town planning in the United States. We saw these ideas as extraordinary and were enthusiastic with the prospects of implementation. I can quite say I volunteer for the job at the time but I did end up in the position of Town Architect in the summer on 1983.</p>

          <p className={styles.bold}>What do you consider your greatest accomplishments during your time as Town Architect?</p>
          <p>Perhaps in helping define the responsibilities and concerns of the position of the Town Architect. We did not exactly know then what role the town architect should play in town design and city building, even though we were inspired and encouraged by examples we studied in history like Giotto in Florence and Schinckel in Berlin, for instance. We soon realized though, the value of having an architect on site working with the developer, engineers and surveyors in implementing a master plan. I feel my contribution was in helping to recover this tradition in city building that had been abandoned by the discipline of architecture.</p>

          <p className={styles.bold}>What were common challenges you found while working in Seaside?</p>
          <p>The idea of town building was a new concept then. Conceptually it was an extraordinary proposition but the implementation of the idea was less predictable. Even though I never saw Robert Davis of André Duany lose their composure nor did we ever lack support from the odd collection of characters congregated on the site the uncertainty of the outcome was, for me, worrisome. It was though, a compellingly beautiful setting and after years in school in New York City, I welcomed the forlorn quality and melancholy of what could be understood as wilderness.</p>

          <p className={styles.bold}>How do you feel about Seaside today?</p>
          <p>Despite its practical and theoretical success I see Seaside today much as I saw it then. I understood then that the value of what André, Lizz and Robert were proposing was nothing less than the return of Architecture to Urbanism. I realized architecture could never again be viewed as separate from the design of cities and that we, as architects, were engaged in an activity familiar to Vitruvius or Palladio.</p>

          <p className={styles.bold}>Do you consider yourself an Urbanist or New Urbanist? How do you feel Seaside fits in with modern urban philosophy?</p>
          <p>Seaside and New Urbanism, regardless of the many questions raised, remain the only coherent and systematic proposal for city building today. On a theoretical level, even academic, the recovery of the study of the city as an endeavor of architecture is complete but in practice, outside of the exceptional positions proposed by individuals, Rem Koolhass, as an example, there is no other proposal that can address the construction of the city in a predictable and measured manner and in this sense New Urbanism plays a central defining role in modern urban thinking. I remain an urbanist thirty years after the first drawings of Seaside because I appreciate varied urban environment but I don’t recognize outside of New Urbanism any coherent proposal for the construction of the city.</p>

          <p className={styles.bold}>Can you give a brief summary of what you are doing today, including any recent projects, awards, or positions?</p>
          <p>Teófilo Victoria holds a Masters of Architecture and Urban Design Degree from Columbia University, a Bachelors of Fine Arts and a Bachelors of Architecture from the Rhode Island School of Design. He has been visiting professor at Harvard University and Cornell University and has lectured and participated in juries at the University of Maryland, the University of Notre Dame, and the Instituto Universitario di Architettura in Venice. At the University of Miami he was Undergraduate Program Director from 1995 to 1998 and from 1999 to 2009, Graduate Program Director.</p>

          <p>He has exhibited at the University of Pennsylvania and at the Institute of Classical Architecture in New York. In 1992 he co-edited Between Two Towers, The Drawings of the School of Miami, with Vincent Scully, Catherine Lynn and Horge Hernandez and was the guest editor of Archivos de Arquitectura Antillana in 2009. He is a member of The Congress for The New Urbanism and of the Institute of Classical Architecture and Classical America. He is a principal in delaGuardiaVictoria Architects and Urbanists and was the recipient, together with his partner Maria de la Guardia, of the 2008 Palladio Award for Excellence in Classical and Traditional Design. In 2009, the firm earned a Charter Award from The Congress for The New Urbanism and in 2010, The Philip Trammell Schutze Award.</p>

          <p>The office of DLGV practices classical architecture and traditional urbanism in the United States and overseas. It derives its theoretical constructs from pedagogy at the School of Architecture and from its own research resources. The office publishes Canoa, a Journal of the Architecture and Urbanism of the Greater Caribbean and is engaged with the Florida Chapter of the Institute of Classical Architecture and Classical America in advancing the practice and appreciation of classical and traditional architecture in Florida and the Caribbean.</p>

          <p>At the School of Architecture, Teófilo Victoria teaches theory of architecture, design studio and an on-site course on the architecture of the Renaissance architect Andrea Palladio. Since 1990 he has been the coordinator of the Open City Studio, an itinerant architecture and urbanism studio focused on the relationship between urbanism and culture.</p>
        <hr />
        </section>
        <div className={styles.center}><Link to='/essays/town-architects'>Back to Town Architects</Link></div>
      </section>
    </Layout>
  )
}

export default SurveyTeofilo
